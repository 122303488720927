.loader {
    background-color: rgba(0, 0, 0, 0.65);
    position: absolute;
    z-index: 10000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}


.spinner {
    width: 48px;
    height: 48px;
    border: 6px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    position: relative;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}